<template>
    <div style="min-height: 300px; max-height: 70vh" class="tw-overflow-scroll">
        <table class="tw-w-full table">
            <thead>
                <tr>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-text-center tw-w-4"
                    >
                        No
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32"
                    >
                        Driver Code
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"
                    >
                        Driver Name
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-36"
                    >
                        Transaction Date
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-36"
                    >
                        Voucher No
                    </th>
                    <!-- <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-20"
                    >
                        FX Rate
                    </th> -->
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32 tw-whitespace-nowrap"
                    >
                        CASH ON DELIVERY
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-48"
                    >
                        Collected USD
                    </th>
                    <th
                        class="tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-48"
                    >
                        Collected KHR
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(dateItem, indexDate) in resources.group">
                    <tr class="tw-bg-gray-200" :key="indexDate">
                        <td
                            class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            colspan="8"
                        >
                            <b>Received Date:</b> {{ indexDate }}
                        </td>
                    </tr>
                    <template v-for="(items, cashier) in dateItem">
                        <tr class="tw-bg-gray-200" :key="cashier + indexDate">
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                                colspan="5"
                            >
                                <b>Cashier Name:</b> {{ cashier }}
                            </td>
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                <ts-accounting-format
                                    :digit="2"
                                    :value="
                                        sumByColumn(
                                            items,
                                            'amount_tobe_collected'
                                        )
                                    "
                                    class="tw-font-extrabold tw-text-blue-600"
                                ></ts-accounting-format>
                            </td>
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                <ts-accounting-format
                                    :digit="2"
                                    :value="
                                        sumByColumn(
                                            items,
                                            'amount_tobe_cleared_usd'
                                        )
                                    "
                                    class="tw-font-extrabold tw-text-blue-600"
                                ></ts-accounting-format>
                            </td>
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                <ts-accounting-format
                                    :digit="0"
                                    currency="KHR"
                                    :value="
                                        sumByColumn(
                                            items,
                                            'amount_tobe_cleared_khr'
                                        )
                                    "
                                    class="tw-font-extrabold tw-text-blue-600"
                                ></ts-accounting-format>
                            </td>
                        </tr>
                        <tr
                            v-for="(i, index) in items"
                            :key="
                                i.voucher_no +
                                    '_' +
                                    i.driver_code +
                                    '_' +
                                    index +
                                    '_' +
                                    i.amount_tobe_collected
                            "
                        >
                            <td
                                class="tw-border-b tw-text-center tw-px-3 tw-py-1"
                            >
                                {{ index + 1 }}
                            </td>
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                {{ i.driver_code }}
                            </td>
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                {{ i.driver_name }}
                            </td>
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                {{ i.transaction_date }}
                            </td>
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                {{ i.voucher_no }}
                            </td>
                            <!-- <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                {{ i.fx_rate }}
                            </td> -->
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                <ts-accounting-format
                                    :digit="2"
                                    :value="i.amount_tobe_collected"
                                ></ts-accounting-format>
                            </td>
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                <ts-accounting-format
                                    :digit="2"
                                    :value="i.amount_tobe_cleared_usd"
                                ></ts-accounting-format>
                            </td>
                            <td
                                class="tw-border-b tw-text-left tw-px-3 tw-py-1"
                            >
                                <ts-accounting-format
                                    :digit="2"
                                    :value="i.amount_tobe_cleared_khr"
                                ></ts-accounting-format>
                            </td>
                        </tr>
                    </template>
                    <tr :key="indexDate + '__'">
                        <td height="20" colspan="8"></td>
                    </tr>
                </template>
            </tbody>
            <tfoot>
                <tr>
                    <td height="20" colspan="8"></td>
                </tr>
                <tr class="tw-bg-gray-200">
                    <td
                        class="tw-border-b tw-text-right tw-px-3 tw-py-1"
                        colspan="5"
                    >
                        <b class="tw-uppercase">Grand Total:</b>
                    </td>
                    <td class="tw-border-b tw-text-left tw-px-3 tw-py-1">
                        <ts-accounting-format
                            :digit="2"
                            :value="
                                resources.amount_group
                                    ? resources.amount_group
                                          .amount_tobe_collected
                                    : 0
                            "
                            class="tw-font-extrabold"
                        ></ts-accounting-format>
                    </td>
                    <td class="tw-border-b tw-text-left tw-px-3 tw-py-1">
                        <ts-accounting-format
                            :digit="2"
                            :value="
                                resources.amount_group
                                    ? resources.amount_group
                                          .amount_tobe_cleared_usd
                                    : 0
                            "
                            class="tw-font-extrabold"
                        ></ts-accounting-format>
                    </td>
                    <td class="tw-border-b tw-text-left tw-px-3 tw-py-1">
                        <ts-accounting-format
                            :digit="2"
                            :value="
                                resources.amount_group
                                    ? resources.amount_group
                                          .amount_tobe_cleared_khr
                                    : 0
                            "
                            class="tw-font-extrabold"
                        ></ts-accounting-format>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import { sumBy } from 'lodash'
export default {
    name: 'cashCollectComponent',
    props: ['resources'],
    methods: {
        sumByColumn (resources, column) {
            return sumBy(resources, column)
        }
    }
}
</script>

<style lang="css" scoped>
::-webkit-scrollbar {
    width: 8px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: rgb(161, 3, 3);
}
.table > thead > tr > th {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999;
    background-color: rgba(59, 130, 246);
}
.table > tbody > tr > td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border: none !important;
}

.table > tfoot {
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: white;
}
</style>
