var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-overflow-scroll",staticStyle:{"min-height":"300px","max-height":"70vh"}},[_c('table',{staticClass:"tw-w-full table"},[_vm._m(0),_c('tbody',[_vm._l((_vm.resources.group),function(dateItem,indexDate){return [_c('tr',{key:indexDate,staticClass:"tw-bg-gray-200"},[_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1",attrs:{"colspan":"8"}},[_c('b',[_vm._v("Received Date:")]),_vm._v(" "+_vm._s(indexDate)+" ")])]),_vm._l((dateItem),function(items,cashier){return [_c('tr',{key:cashier + indexDate,staticClass:"tw-bg-gray-200"},[_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1",attrs:{"colspan":"5"}},[_c('b',[_vm._v("Cashier Name:")]),_vm._v(" "+_vm._s(cashier)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold tw-text-blue-600",attrs:{"digit":2,"value":_vm.sumByColumn(
                                        items,
                                        'amount_tobe_collected'
                                    )}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold tw-text-blue-600",attrs:{"digit":2,"value":_vm.sumByColumn(
                                        items,
                                        'amount_tobe_cleared_usd'
                                    )}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold tw-text-blue-600",attrs:{"digit":0,"currency":"KHR","value":_vm.sumByColumn(
                                        items,
                                        'amount_tobe_cleared_khr'
                                    )}})],1)]),_vm._l((items),function(i,index){return _c('tr',{key:i.voucher_no +
                                '_' +
                                i.driver_code +
                                '_' +
                                index +
                                '_' +
                                i.amount_tobe_collected},[_c('td',{staticClass:"tw-border-b tw-text-center tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(i.driver_code)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(i.driver_name)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(i.transaction_date)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_vm._v(" "+_vm._s(i.voucher_no)+" ")]),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{attrs:{"digit":2,"value":i.amount_tobe_collected}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{attrs:{"digit":2,"value":i.amount_tobe_cleared_usd}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{attrs:{"digit":2,"value":i.amount_tobe_cleared_khr}})],1)])})]}),_c('tr',{key:indexDate + '__'},[_c('td',{attrs:{"height":"20","colspan":"8"}})])]})],2),_c('tfoot',[_vm._m(1),_c('tr',{staticClass:"tw-bg-gray-200"},[_vm._m(2),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold",attrs:{"digit":2,"value":_vm.resources.amount_group
                                ? _vm.resources.amount_group
                                      .amount_tobe_collected
                                : 0}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold",attrs:{"digit":2,"value":_vm.resources.amount_group
                                ? _vm.resources.amount_group
                                      .amount_tobe_cleared_usd
                                : 0}})],1),_c('td',{staticClass:"tw-border-b tw-text-left tw-px-3 tw-py-1"},[_c('ts-accounting-format',{staticClass:"tw-font-extrabold",attrs:{"digit":2,"value":_vm.resources.amount_group
                                ? _vm.resources.amount_group
                                      .amount_tobe_cleared_khr
                                : 0}})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-text-center tw-w-4"},[_vm._v(" No ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32"},[_vm._v(" Driver Code ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2"},[_vm._v(" Driver Name ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-36"},[_vm._v(" Transaction Date ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-36"},[_vm._v(" Voucher No ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-32 tw-whitespace-nowrap"},[_vm._v(" CASH ON DELIVERY ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-48"},[_vm._v(" Collected USD ")]),_c('th',{staticClass:"tw-border tw-uppercase tw-bg-blue-500 tw-text-white tw-py-2 tw-px-2 tw-w-48"},[_vm._v(" Collected KHR ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{attrs:{"height":"20","colspan":"8"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"tw-border-b tw-text-right tw-px-3 tw-py-1",attrs:{"colspan":"5"}},[_c('b',{staticClass:"tw-uppercase"},[_vm._v("Grand Total:")])])}]

export { render, staticRenderFns }